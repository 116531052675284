export function getEnv() {
  const { location: { hostname } } = window;
  switch (hostname) {
    case 'localhost':
      return 'development';
    case 'app-staging.covetool.com':
      return 'staging';
    default:
      return 'production';
  }
}
export const ENV = getEnv();
