import getClient from 'client';
import axios from 'axios';

import {
  IEUIResults, IEUIRun, IProject, IProjectDetail,
} from 'types/project';
import { CoveResponse, CoveResPaginated } from 'types/client';

const client = getClient('cove');
const URL_PROCORE = '/integrations/procore';

/** Get a paginated list of projects from the API
 *
 * @param limit number of projects to retrieve
 * @param offset count to start returning after
 * @returns promise resolving to a api response
 */
export async function getProjectQuery(
  limit: number,
  offset: number,
  search: string,
): Promise<CoveResPaginated<IProject[]>> {
  const params = { limit, offset, search };
  const res: CoveResPaginated<IProject[]> = await client
    .get(`${URL_PROCORE}/projects`, { params });
  return res;
}

/** Retrieve the details of a single project given it's id/pk
 *
 * @param id id of a specific project
 * @returns promise resolving to a project object
 */
export async function getProject(id: number): Promise<IProjectDetail> {
  try {
    const res = await client
      .get(`${URL_PROCORE}/projects/find?procore_id=${id}`) as CoveResponse<IProjectDetail>;
    const { data: { data } } = res;
    return data;
  } catch (err: Error | any) {
    if (axios.isAxiosError(err)) {
      if (err.response === undefined) {
        throw err;
      }
      const { response: { status } } = err;
      if ([404, 401, 403, 400].includes(status)) {
        throw new Error(String(status));
      }
      throw new Error('Unknown');
    } else {
      throw err;
    }
  }
}

/** Link a procore project (id) to an existing project within the cove API
 *
 * @param id id of a cove.tool project
 * @param procoreId id the procore project to link with
 * @returns resolves to undefined or an error if the project was unable to be
 * linked
 */
export async function linkProject(id: number, procoreId: number) {
  try {
    await client.post(`${URL_PROCORE}/projects/${id}/link`, {
      procore_id: procoreId,
    });
    return undefined;
  } catch (err) {
    throw new Error('Failed to link projects.');
  }
}

/** Unlink a project that has already been linked with a procore project
 *
 * @param procoreId unique id of the procore project that the cove.tool
 * project has been linked to
 * @returns a promise resolving to undefined if successful, otherise, an
 * error is thrown.
 */
export async function unlinkProject(procoreId: number) {
  try {
    await client.get(`${URL_PROCORE}/projects/${procoreId}/unlink`);
    return undefined;
  } catch (err) {
    throw new Error('Failed to link projects.');
  }
}

/** Retrieve the EUI results for a specific project given it's id
 *
 * @param projectId unique identifier for the project to retrieve results for
 * @returns total eui results as well as a breakdown of EUI per category
 */
export async function getEUI(projectId: number): Promise<IEUIRun[]> {
  try {
    const res = await client
      .get(`/projects/${projectId}/eui`) as CoveResponse<IEUIResults>;
    const { data: { data: { runs } } } = res;
    return runs;
  } catch (err) {
    throw new Error('Failed to get EUI results');
  }
}
