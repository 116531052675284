import getClient from 'client';

import { ResponseSignIn } from 'types/client';

import { KEY_API_TOKEN, KEY_PLUGIN_AUTHORIZATION, KEY_EMAIL } from 'const';

const client = getClient('cove');

export default async function authenticate(email: string, pluginKey: string) {
  const msg = 'Could not authenticate plugin.';
  try {
    const body = { username: email, password: pluginKey };
    const res: ResponseSignIn = await client.post('/auth/signin', body);
    if (res.status !== 200) {
      throw new Error(msg);
    }
    const { data: { token } } = res;
    localStorage.setItem(KEY_API_TOKEN, token);
    localStorage.setItem(KEY_EMAIL, email);
    localStorage.setItem(KEY_PLUGIN_AUTHORIZATION, pluginKey);
    return;
  } catch (err) {
    throw new Error(msg);
  }
}
