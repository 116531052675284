import React, { FormEvent, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  FormControl, FormLabel, Input, Container, Button, Center, Image,
} from '@chakra-ui/react';

import authenticate from 'services/Authentication';
import {
  PARAM_PROCORE_PROJECT_ID, KEY_EMAIL,
  KEY_PLUGIN_AUTHORIZATION,
} from 'const';

import logo from 'assets/covetool-black.png';
import styles from 'styles/Main.module.sass';

interface IAuthForm extends HTMLDivElement {
  email: HTMLInputElement;
  pluginKey: HTMLInputElement;
}

function Auth() {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    const email = localStorage.getItem(KEY_EMAIL);
    const pluginKey = localStorage.getItem(KEY_PLUGIN_AUTHORIZATION);
    const projectId = params.get(PARAM_PROCORE_PROJECT_ID);
    if (projectId && pluginKey) {
      navigate(`/projects/${projectId}`);
    } else if (email && pluginKey) {
      navigate('/projects');
    }
  }, []);

  const didSubmit = async (event: FormEvent<IAuthForm>) => {
    event.preventDefault();
    const { currentTarget: { email, pluginKey } } = event;
    const projectId = params.get(PARAM_PROCORE_PROJECT_ID);
    await authenticate(email.value, pluginKey.value);
    let url = '/projects';
    if (projectId) {
      url = `/projects/${projectId}`;
    }
    navigate(url);
  };

  return (
    <Container className={styles.main}>
      <Center height="100%">
        <FormControl
          className={styles.form}
          as="form"
          onSubmit={(e) => didSubmit(e as FormEvent<IAuthForm>)}
        >
          <Center>
            <Image src={logo} className={styles.logo} />
          </Center>
          <FormLabel className={styles.legend} as="legend">
            cove.tool Plugin Authentication
          </FormLabel>
          <FormLabel htmlFor="email">
            Email
            <Input id="email" type="email" name="email" />
          </FormLabel>
          <FormLabel htmlFor="pluginKey">
            Plugin Activation Key
            <Input id="pluginKey" name="pluginKey" />
          </FormLabel>
          <Button type="submit">Submit</Button>
        </FormControl>
      </Center>
    </Container>
  );
}

export default Auth;
