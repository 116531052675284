import React, { useEffect, useState, useRef } from 'react';
// @ts-ignore
import { DonutChart, donutOptions } from '@covetool/ebrik';

import { getEUI } from 'services/Projects';

export default function EuiBreakdown({ projectId }: { projectId: number }) {
  const [data, setData] = useState<any>();
  const ref = useRef<SVGAElement>();

  useEffect(() => {
    if (ref?.current === undefined) return;
    ref.current.style.width = '100%';
    setData({ ...data });
  }, [ref?.current?.clientWidth]);

  useEffect(() => {
    if (!projectId) return;
    getEUI(projectId)
      .then((res) => {
        const [run] = res;
        const { eui_breakdown: breakdown } = run;
        const newData = breakdown.headers
          .map((d, i) => ({
            name: d,
            y: parseFloat(breakdown.values[i].toFixed(2)),
          }));
        const options = donutOptions({
          height: '100%',
          display: '',
          hover: true,
          graphData: newData,
          chart: { width: '300px' },
        });
        options.series[0] = {
          ...options.series[0],
          size: '90%',
          innerSize: '90%',
        };
        setData(options);
      });
  }, [projectId]);

  return (
    <DonutChart
      ref={ref}
      chartOptions={data}
      style={{ width: '100%' }}
      containerProps={{
        style: { width: ref?.current?.style.width },
      }}
    />
  );
}
