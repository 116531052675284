import React from 'react';

import { IconButton, HStack, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface IProps {
  limit: number;
  offset: number;
  total: number;
  setOffset: (x: number) => void;
}

export default function Pagination({
  limit, offset, total, setOffset,
}: IProps) {
  const numPages = Math.ceil(total / limit);
  const current = Math.ceil(offset / limit) + 1;

  const didClickNext = () => {
    setOffset(offset + limit);
  };

  const didClickPrev = () => {
    setOffset(offset - limit);
  };

  return (
    <HStack>
      <IconButton
        aria-label="Previous Page"
        disabled={current === 1}
        onClick={didClickPrev}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Text>
        {'Page '}
        <strong>{current}</strong>
        {' of '}
        <strong>{numPages}</strong>
      </Text>
      <IconButton
        aria-label="Next Page"
        disabled={current === numPages}
        onClick={didClickNext}
      >
        <ChevronRightIcon />
      </IconButton>
    </HStack>
  );
}
