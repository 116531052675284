import React, { FormEvent, useState } from 'react';
import { range } from 'lodash-es';
import { useQuery } from 'react-query';
import { useSearchParams, useNavigate } from 'react-router-dom';

import {
  Container, Skeleton, Heading, Divider, Input, InputLeftAddon, InputRightElement,
  InputGroup, Button, HStack,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

import ProjectItem from 'components/Project';
import Pagination from 'components/Pagination';
import SignOut from 'components/SignOut';

import { getProjectQuery } from 'services/Projects';
import { ENV } from 'utils';
import { PARAM_PROCORE_PROJECT_ID } from 'const';

import classes from 'styles/ProjectList.module.sass';

export default function ProjectList() {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const hasProject = params.has(PARAM_PROCORE_PROJECT_ID);
  const { data, status } = useQuery(
    ['projects', limit, offset, total, search],
    async () => {
      try {
        const res = await getProjectQuery(limit, offset, search);
        const { pagination } = res.data;
        setLimit(pagination.limit);
        setOffset(pagination.offset);
        setTotal(pagination.total);
        return res.data.data;
      } catch (err) {
        const msg = (err as Error)?.message;
        if (msg === 'Failed Sign In') {
          localStorage.clear();
          navigate('/');
          return undefined;
        }
        throw err;
      }
    },
  );

  let content;
  if (status === 'loading') {
    content = range(10).map((i) => <Skeleton key={i} className={classes.skeleton} />);
  } else if (status === 'error') {
    content = 'An unexpected error has occurred';
  } else {
    content = data
      ?.map(({ id, name }) => <ProjectItem id={id} name={name} key={id} />);
  }
  const searchDidChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchValue(value);
  };

  const didClickSearch = () => {
    setSearch(searchValue);
    setOffset(0);
  };

  return (
    <Container>
      <Heading className={classes.h1}>
        { !hasProject ? 'Projects' : 'Select a Project to Link' }
      </Heading>
      <Divider className={classes.divider} />
      <InputGroup size="md" variant="outline" className={classes.search}>
        <InputLeftAddon pointerEvents="none">
          <SearchIcon />
        </InputLeftAddon>
        <Input placeholder="Projects" onChange={searchDidChange} />
        <InputRightElement width="4.5rem">
          <Button onClick={didClickSearch}>Search</Button>
        </InputRightElement>
      </InputGroup>
      <Container className={classes.list}>
        { content }
      </Container>
      <HStack marginTop="1rem" justifyContent="space-between">
        <Pagination
          total={total}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
        />
        <Button
          role="link"
          colorScheme="blue"
          backgroundColor="coveBlue"
          onClick={() => {
            if (ENV === 'development') {
              window.open('http://localhost:8000/project/create');
            } else {
              window.open('https://app.covetool.com/project/create');
            }
          }}
        >
          Create New Project
        </Button>
      </HStack>
      <HStack justifyContent="flex-end" marginTop="2rem">
        <SignOut />
      </HStack>
    </Container>
  );
}
