import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Container, Text, Flex, Heading, Button, StatGroup, Stat, StatLabel, StatNumber,
  Tag, HStack, Center, Image, Box,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import EUIBreakdown from 'components/EUIBreakdown';

import { PARAM_PROCORE_PROJECT_ID } from 'const';
import { getProject, unlinkProject } from 'services/Projects';
import { IProjectDetail } from 'types/project';

import classes from 'styles/ProjectDetails.module.sass';
import logo from 'assets/covetool-black.png';

export default function ProjectDetails() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const projectId = parseInt(id, 10);
  const [project, setProject] = useState<IProjectDetail>();
  const linkURL = `/projects?${PARAM_PROCORE_PROJECT_ID}=${projectId}`;
  const baseURL = `/?${PARAM_PROCORE_PROJECT_ID}=${projectId}`;

  /** Decouple a procore project from the currently selected
   * covetool project
   *
   * @param proId procore id of the project to link
   */
  const didUnlink = async (proId: number) => {
    try {
      await unlinkProject(proId);
      navigate(linkURL);
    } catch (err) {
      console.error(err);
    }
  };

  /** Open project in main cove application */
  const didOpenExternal = () => {
    const { REACT_APP_COVE_URL } = process.env;
    if (!project) {
      return;
    }
    const { id: pk } = project;
    window.open(
      `${REACT_APP_COVE_URL}/project/${pk}/change/`,
      '_blank',
    );
  };

  useEffect(() => {
    getProject(projectId)
      .then((res) => setProject(res))
      .catch((err: Error) => {
        const { message: msg } = err;
        if (['404', '403'].includes(msg)) {
          navigate(linkURL);
        } else if (['401', '400', 'Failed Sign In'].includes(msg)) {
          localStorage.clear();
          navigate(baseURL);
        }
      });
  }, [id]);

  if (!project) {
    return (
      <Container>
        <Text>Project Not found</Text>
      </Container>
    );
  }
  let created;
  if (project?.created_at) {
    created = new Date(project?.created_at)
      .toDateString();
  }
  return (
    <Center marginBottom="2rem">
      <Flex>
        <Container>
          <Flex className={classes.card} flexDir="column">
            <HStack marginBottom="2rem">
              <Box boxSize="3rem">
                <Image src={logo} alt="covetool-logo" />
              </Box>
              <Heading className={classes.h1} as="h1" size="xl" paddingTop=".3rem">
                Project
              </Heading>
            </HStack>
            <StatGroup className={classes.row}>
              <Stat>
                <StatLabel>Name</StatLabel>
                <StatNumber>{project?.name}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Owner</StatLabel>
                <StatNumber>
                  {project?.created_by?.first_name}
                  {' '}
                  {project?.created_by?.last_name}
                </StatNumber>
              </Stat>
            </StatGroup>
            <Flex className={classes.row}>
              <Stat>
                <StatLabel>Location</StatLabel>
                <StatNumber>{project?.location?.components?.city}</StatNumber>
                <StatNumber>{project?.location?.administrative_area}</StatNumber>
                <StatNumber>{project?.location?.components?.country}</StatNumber>
              </Stat>
              <Stat>
                <Text marginBottom=".4rem">Building Types</Text>
                <HStack>
                  {project?.building_types?.map((s) => <Tag size="lg" key={s}>{s}</Tag>)}
                </HStack>
              </Stat>
            </Flex>
            <StatGroup className={classes.row}>
              <Stat>
                <StatLabel>Created</StatLabel>
                {created && <StatNumber>{created}</StatNumber>}
              </Stat>
            </StatGroup>
          </Flex>
          <Flex className={classes.card} flexDir="column">
            <Heading as="h1" size="xl">Climate &#38; Energy</Heading>
            <StatGroup className={classes.stats} flexDir="row">
              <Stat>
                <StatLabel>Climate Zone </StatLabel>
                <StatNumber>{project?.climate_zone?.name}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Energy Code</StatLabel>
                <StatNumber>{project?.energy_code?.name}</StatNumber>
              </Stat>
            </StatGroup>
          </Flex>
          <Flex className={classes.card} justifyContent="space-between">
            <Button
              className={classes.button}
              onClick={() => didUnlink(project?.id)}
              variant="outline"
            >
              Unlink Project
            </Button>
            <Button
              role="link"
              colorScheme="blue"
              onClick={didOpenExternal}
              rightIcon={<ArrowForwardIcon />}
            >
              View in cove.tool
            </Button>
          </Flex>
        </Container>
        <Container>
          <Flex className={classes.card} flexDir="column">
            <Heading as="h1" size="xl">Energy Use Intensity (EUI)</Heading>
            <StatGroup className={classes.stats} flexDir="row">
              <Stat>
                <StatLabel>EUI</StatLabel>
                <StatNumber>{project?.eui?.toFixed(2)}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>CBECS EUI</StatLabel>
                <StatNumber>{project?.cbecs_eui?.toFixed(2)}</StatNumber>
              </Stat>
            </StatGroup>
            <Center>
              <EUIBreakdown projectId={project?.id} />
            </Center>
          </Flex>
        </Container>
      </Flex>
    </Center>
  );
}
