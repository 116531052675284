import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Center } from '@chakra-ui/react';

export default function SignOut() {
  const navigate = useNavigate();
  const didClick = () => {
    localStorage.clear();
    navigate('/');
  };
  return (
    <Center padding="0.5rem">
      <Button onClick={didClick}>Sign Out</Button>
    </Center>
  );
}
