import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Flex, Text, IconButton, Spacer, Container, Tooltip,
} from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';

import { linkProject } from 'services/Projects';
import { PARAM_PROCORE_PROJECT_ID } from 'const';

import classes from 'styles/Project.module.sass';

interface IProps {
  id: number;
  name: string;
}
const msg = 'You must have a Procore project selected in order to links projects.';

export default function Project({ id, name }: IProps) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const procoreId = params.get(PARAM_PROCORE_PROJECT_ID);

  const didClick = async () => {
    if (!procoreId) {
      return;
    }
    await linkProject(id, parseInt(procoreId, 10));
    navigate(`/projects/${procoreId}`);
  };
  return (
    <Container key={id} className={classes.container}>
      <Flex className={classes.project}>
        <Text className={classes.project__text} fontSize="1.rem">{name}</Text>
        <Spacer />
        <Tooltip
          shouldWrapChildren
          label={msg}
          isDisabled={Boolean(procoreId)}
        >
          <IconButton
            role="link"
            aria-label="Link Project"
            icon={<LinkIcon />}
            onClick={didClick}
            disabled={!procoreId}
          />

        </Tooltip>
      </Flex>
    </Container>
  );
}
